import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { Box, Button, Grid, Typography, Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import FacebookIcon from "@material-ui/icons/Facebook"
// import LinkedInIcon from '@material-ui/icons/LinkedIn'
// import TwitterIcon from '@material-ui/icons/Twitter'

const useStyles = makeStyles(theme => ({
  root: {},
  containerPart: {
    [theme.breakpoints.down("md")]: {
      marginTop: "6em",
    },
    marginTop: "10em",
  },
  linksStyles: {
    textDecoration: "underline",
    color: theme.palette.common.black,
    fontWeight: 500,
    "&:hover": {
      color: "#291C64",
    }
  },
}))

export default function Contact() {
  const classes = useStyles()
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CFL | Atención al Cliente</title>
      </Helmet>
      <Container className={classes.containerPart}>
        <Box maxWidth="80%" m="auto">
          <Box mt={4} py={2}>
            <Typography variant="h1" component="h1" gutterBottom>
              <b>Póngase en contacto con nosotros</b>
            </Typography>
          </Box>
          <Box mb={4} py={2}>
            <Typography variant="body1" gutterBottom inline>
              <b>
                Siempre nos complace saber de usted con respecto a cualquier
                pregunta que pueda tener.
              </b>
            </Typography>
          </Box>
          <Box py={2} maxWidth={700} margin="auto">
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" inline gutterBottom>
                  Correo electrónico
                </Typography>
                <Typography variant="body1" inline gutterBottom>
                  <MailOutlineIcon /> por correo en{" "}
                  <a
                    href="mailto: info@capitalfundinglab.com"
                    className={classes.linksStyles}
                  >
                    info@capitalfundinglab.com
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" inline gutterBottom>
                  Síguenos
                </Typography>
                <Box>
                  <Button
                    size="medium"
                    href="https://www.facebook.com/capitalfundinglab"
                    className={classes.button}
                    startIcon={<FacebookIcon />}
                  >
                    Facebook
                  </Button>
                </Box>
                {/* <Button
                    size="medium"
                    href="https://twitter.com/Capital_FLab"
                    className={classes.button}
                    startIcon={<TwitterIcon />}
                  >
                    Twitter
                  </Button>
                  
                </Typography>
                <Typography variant="body1" inline gutterBottom>
                <Button
                    size="medium"
                    href="https://mx.linkedin.com/company/capital-funding-lab"
                    className={classes.button}
                    startIcon={<LinkedInIcon />}
                  >
                    LinkedIn
                  </Button> */}
              </Grid>
              {/*<Grid item xs={12} md={6}>
                <Typography variant="h3" inline gutterBottom>
                  Teléfono
                </Typography>
                <Typography variant="body1" inline gutterBottom>
                  <PhoneIcon/> <b>+ 52 55 85265645</b>
                  <Typography variant="body1" inline gutterBottom>
                    Abierto de 9 am - 5 pm, Lunes a Viernes
                  </Typography>
                </Typography>
              </Grid>*/}
              <Grid item xs={12} md={6}>
                <Typography variant="h3" inline gutterBottom>
                  Contáctenos por correo ordinario
                </Typography>
                <Typography variant="body1" inline gutterBottom>
                  Capital Funding Lab, <br />
                  Puebla 237, <br />
                  Colonia Roma Norte, CP 06700, <br />
                  Ciudad de México, CDMX
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}
